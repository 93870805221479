@use '@cloudscape-design/design-tokens/index' as awsui;

.header {
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    margin: 0;
    background-color: #0f1b2a;
    border-bottom: solid 1px #414d5c;
}

.full-header {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 400px;
  justify-content: right;
  padding-top: awsui.$space-static-xxxl;
  padding-bottom: awsui.$space-static-xxxl;
  background-color: awsui.$color-background-layout-main;
}

.inner-header {
  max-width: 500px;
  margin-left: awsui.$space-scaled-m;
  margin-right: awsui.$space-scaled-m;
}

.header-background-image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: auto;

  background-size: cover;
  background-position: top center;
}

#waitlist {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 550px;
}

.waitlist-iframe {
  width: 100%;
  border: 0px;
  margin: 0px;
}

.content {
  width: 100%;
  overflow: hidden;
}

.footer {
  background-color: #0f1b2a;
  width: 100%;
  text-align: center;
  justify-content: center;
}